import {get,post} from "@/utils/axios"

const getSoftwareClassify = p => get('api-common/software/getSoftwareClassifyList',p);

const getSoftwareGoodsList = data => post('/api-common/software/getSoftwareStoreGoodsList',data);


export default {
    getSoftwareClassify,
    getSoftwareGoodsList,
}
