<template>
    <el-row style="background-color: #F7F7F7">
        <el-col :xs="0" :sm="24" :md="24" :lg="24" :xl="24" class="banner" align="middle" justify="center">
            <el-col class="banner-text">
                <el-col>搜索</el-col>
            </el-col>
            <el-col>
                <div class="father_box" :style="inputStyle">
                <!--  输入框  -->
                    <el-input  v-model="seoKeyword" @keyup.enter.native ="addTags" placeholder="请输入你要查找的产品名称"
                         ref="inputTag">
                    <!-- <el-button slot="append" @click="search"><img src="@/img/softwaremall/sw_search.png"></el-button> -->
                    </el-input>
                    <el-button @click="addTags" class="search-button"><img src="@/img/softwaremall/sw_search.png"></el-button>
                </div>
            </el-col>
            <el-col>
                <el-col class="search-key">
                    <span style="padding-right: 10px">热门搜索：</span>
                    <span v-if="brandType.length != 0" class="cursor-item" v-for="(item, index) in brandType.slice(0,4)" :key="index"
                          @click="hotSearch(item.softwareClassifyName)" :data-id = "item.softwareClassifyName" style="padding-right: 10px">
                        {{item.softwareClassifyName}}
                    </span>
                </el-col>
            </el-col>
        </el-col>
        <el-col :xs="0" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 20px" align="middle" justify="center">
            <el-col class="tabs-card">
                <el-card class="box-card" shadow="never">
                    <el-tabs v-model="activeName"  id="tabs" style="text-align: left">
                    <!--    品牌     -->
                        <el-tab-pane label="品牌" name="first">
                            <span><el-link href="javascript:;" :underline="false"
                                           class="tabs-text" :class="{'tabs-active': totalIsSelect[0].isSelect}"  @click.native="allSelect('1')">全部</el-link></span>
                            <span v-for="(item,index) in brandType" :key="index" style="margin-bottom: 30px">
                                <el-link href="javascript:;" :underline="false" class="tabs-text" :class="{'tabs-active': item.isSelect}"
                                         @click.native="jumpSoftwareStore(item.softwareClassifyName, item.softwareClassifyType ,index)">
                                    {{item.softwareClassifyName}}
                                </el-link>
                            </span>
                        </el-tab-pane>
                    <!--    功能分类     -->
                        <el-tab-pane label="功能分类" name="second">
                            <span><el-link href="javascript:;" :underline="false"
                                           class="tabs-text" :class="{'tabs-active': totalIsSelect[1].isSelect}" @click.native="allSelect('2')">全部</el-link></span>
                            <span v-for="(item,index) in functionType" :key="index">
                                <el-link href="javascript:;" :underline="false" class="tabs-text" :class="{'tabs-active': item.isSelect}"
                                         @click.native="jumpSoftwareStore(item.softwareClassifyName, item.softwareClassifyType ,index)">
                                    {{item.softwareClassifyName}}
                                </el-link>
                            </span>
                        </el-tab-pane>
                    <!--    行业分类     -->
                        <el-tab-pane label="行业分类" name="third">
                            <span><el-link href="javascript:;" :underline="false"
                                           class="tabs-text" :class="{'tabs-active': totalIsSelect[2].isSelect}"  @click.native="allSelect('3')">全部</el-link></span>
                            <span v-for="(item,index) in industryType" :key="index">
                                <el-link href="javascript:;" :underline="false" class="tabs-text" :class="{'tabs-active': item.isSelect}"
                                         @click.native="jumpSoftwareStore(item.softwareClassifyName, item.softwareClassifyType ,index)">
                                    {{item.softwareClassifyName}}
                                </el-link>
                            </span>
                        </el-tab-pane>
                    </el-tabs>
                </el-card>
            </el-col>
        </el-col>
        <!--    软件列表    -->
        <el-col :xs="0" :sm="24" :md="24" :lg="24" :xl="24" class="software-list">
            <el-col class="software-row" v-if="software.length > 0" v-for="(value,index) in software" :key="index">
                <el-card class="software-col cursor-item" shadow="hover" v-for="(col,index) in value.row" :key="index" @click.native="openProductDetail(col.softwareGoodsId)">
                    <img :src="col.imgUrl" class="software-img">
                    <el-col class="software-name">{{col.softwareGoodsName}}</el-col>
                    <el-col class="software-decribe">订阅、乐享售后服务</el-col>
                    <el-col class="software-price">
                        <span>¥{{col.goodsPrice}}</span>
                        <span class="sw-price-unit" v-if="col.softwareGoodsType == 1">/年</span>
                    </el-col>
                </el-card>
            </el-col>
            <el-col v-if="software.length <= 0" style="text-align: center; height: 180px; margin-top: 30px">
              <img src="@/img/softwaremall/ic_kzt_2.png" alt="" >
            </el-col>
        </el-col>
        <!-- 分页 -->
        <el-col :xs="0" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top:26px; margin-bottom: 59px" align="middle"
                justify="center" v-if="software.length > 0">
            <el-col id="sw-page" >
                <el-pagination
                        class="page-style"
                        layout="pager"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-size="pageSize"
                        :total="totalCount">
                </el-pagination>
            </el-col>

        </el-col>
    </el-row>

</template>

<script>
</script>
<script>
    import softwarelist from "../../api/softwarelist";


    export default {
        name: "SoftwareList",
        data() {
            return {
                activeName: 'first',
                // 搜索字段
                seoKeyword: '',
                keywordTags: [], // 搜索字段标签
                inputWidth: '', // 输入框宽度
                //品牌分类
                brandType: [],
                //品牌Id
                classifyBrandId: '',
                //功能分类
                functionType: [],
                //功能ID
                classifyFunctionId: '',
                //行业分类
                industryType: [],
                //行业Id
                classifyIndustryId: '',
                softwareGoodsList: {},// api 查询软件商城列表数据
                software: [],// 处理查询到的数据并存储
                exbrandName: '',
                exfunctionName: '',
                exindustryName: '',
                currentPage: 1, // 当前页
                pageSize: 20, // 页数
                totalCount: 50, // 总页数
                totalIsSelect:[// 记录全部选中标记
                    {
                        type: 1,
                        isSelect: true
                    },{
                        type: 2,
                        isSelect: true
                    },{
                        type: 3,
                        isSelect: true
                    }
                ],
                selectTabIndex:[-1, -1, -1], //  保存选中条件的索引
            }
        },
        mounted() {
            this.getSoftwareClassify();
        },
        computed: {
            //计算属性：计算出动态输入框宽度
            inputStyle() {
                let style = {};
                // style.width = `${this.inputWidth}px`;
                style['width'] = this.inputWidth + "px";
                return style;
            },
        },
        methods: {
            //查询分类
            getSoftwareClassify() {
                let obj = this;
                softwarelist.getSoftwareClassify().then(res => {
                    if (res.code == 200) {
                        var data = res.data;
                        // 相同类别的数据放在同一个列表
                        data.forEach((item) => {
                            if(item.softwareClassifyType == 1) {
                                item['isSelect'] = false;
                                obj.brandType.push(item);
                            }
                            if(item.softwareClassifyType == 2) {
                                item['isSelect'] = false;
                                obj.functionType.push(item);
                            }
                            if(item.softwareClassifyType == 3) {
                                item['isSelect'] = false;
                                obj.industryType.push(item);
                            }
                        });
                    }
                })
                setTimeout(function () {
                    // obj.classFilter();
                    obj.getSoftwareList();
                }, 200);
            },
            // 查询软件列表
            getSoftwareList() {
                let obj = this;
                let keyword = this.keywordTags;
                let exbrand = [this.exbrandName]
                let exfunction = [this.exfunctionName]
                let exindustry = [this.exindustryName]
                softwarelist.getSoftwareGoodsList({
                    keyword: keyword,
                    pageNo: obj.currentPage,
                    pageSize: obj.pageSize,
                    exbrand: exbrand,
                    exfunction: exfunction,
                    exindustry: exindustry,
                }).then(res => {
                    if (res.code == 200){
                        let data = res.data;
                        obj.totalCount = data.total;
                        obj.softwareGoodsList = {};
                        obj.softwareGoodsList['records'] = data.records;
                        this.handleSwList()
                    }else if (res.code==209){
                        obj.software = [];
                    }

                })
            },
            // 增加标签
            addTags(){
                // 存储关键字段标签
                this.keywordTags = [this.seoKeyword]
                // 查询软件列表
                this.getSoftwareList();
            },
            // 移除标签
            removeTag(tag) {
                this.keywordTags.splice(tag,1);
                if(this.keywordTags.length == 0) {
                    this.inputWidth = 603;
                }
                // 查询软件列表
                this.getSoftwareList();
                // this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
            },
            hotSearch(p){
              this.exbrandName = p;
              this.getSoftwareList();
            },
            // 筛选 点击全部
            allSelect(num) {
                let url = window.location.href;
                let obj = this;
                this.currentPage = 1;
                var index = -1;
                if (num == 1) {
                    // 给“全部”条件增加选中样式
                    obj.totalIsSelect[0].isSelect = true;
                    // 移除其它条件的选中样式
                    if (obj.selectTabIndex[0] != -1) {
                        index = obj.selectTabIndex[0];
                        obj.brandType[index].isSelect = false;
                        obj.selectTabIndex[0] = -1;
                    }
                    // 清除url对应的参数
                    window.location.href = this.delUrlParam(url, "exbrand");
                    obj.exbrandName = ''
                }
                if (num == 2) {
                    // 给“全部”条件增加选中样式
                    obj.totalIsSelect[1].isSelect = true;
                    // 移除其它条件的选中样式
                    if (obj.selectTabIndex[1] != -1) {
                        index = obj.selectTabIndex[1];
                        obj.functionType[index].isSelect = false;
                        obj.selectTabIndex[1] = -1;
                    }
                    // 清除url对应的参数
                    window.location.href = this.delUrlParam(url, "exfunction");
                    obj.exfunctionName = ''
                }
                if (num == 3) {
                    // 给“全部”条件增加选中样式
                    obj.totalIsSelect[2].isSelect = true;
                    // 移除其它条件的选中样式
                    if (obj.selectTabIndex[2] != -1) {
                        index = obj.selectTabIndex[2];
                        obj.industryType[index].isSelect = false;
                        obj.selectTabIndex[2] = -1;
                    }
                    // 清除url对应的参数
                    window.location.href = this.delUrlParam(url, "exindustry");
                    obj.exindustryName =''
                }
                this.getSoftwareList();
            },
            // 筛选 点击分类产品跳转
            jumpSoftwareStore(id, type, index) {
                let obj = this;

                let url = window.location.href
                if (type == 1) {
                    // 移除“全部”的选中样式
                    obj.totalIsSelect[0].isSelect = false;
                    // 清除上一个选中的条件的样式
                    if(obj.selectTabIndex[0] != -1) {
                        let brandIndex = obj.selectTabIndex[0];
                        obj.brandType[brandIndex].isSelect = false;
                    }
                    // 对应的筛选条件增加选中样式，以及保存索引
                    obj.brandType[index].isSelect = true;
                    obj.selectTabIndex[0] = index;
                    // 给url携带对应的参数
                      this.exbrandName = id;
                    // window.location.href = this.changeURLArg(url, 'exbrand', id);
                }
                if (type == 2) {
                    // 移除“全部”的选中样式
                    obj.totalIsSelect[1].isSelect = false;
                    // 清除上一个选中的条件的样式
                    if(obj.selectTabIndex[1] != -1) {
                        let funIndex = obj.selectTabIndex[1];
                        obj.functionType[funIndex].isSelect = false;
                    }
                    // 对应的筛选条件增加选中样式，以及保存索引
                    obj.functionType[index].isSelect = true;
                    obj.selectTabIndex[1] = index;
                    // 给url携带对应的参数
                    obj.exfunctionName = id;
                    // window.location.href = this.changeURLArg(url, 'exfunction', id);
                }
                if (type == 3) {
                    // 移除“全部”的选中样式
                    obj.totalIsSelect[2].isSelect = false;
                    // 清除上一个选中的条件的样式
                    if(obj.selectTabIndex[2] != -1) {
                        let industryIndex = obj.selectTabIndex[2];
                        obj.industryType[industryIndex].isSelect = false;
                    }
                    // 对应的筛选条件增加选中样式，以及保存索引
                    obj.industryType[index].isSelect = true;
                    obj.selectTabIndex[2] = index;
                    // 给url携带对应的参数
                    obj.exindustryName = id;
                    // window.location.href = this.changeURLArg(url, 'exindustry', id);
                }
              this.getSoftwareList();
            },
            // 给url添加对应参数
            changeURLArg(url, arg, arg_val) {
                var pattern = arg + '=([^&]*)';
                var replaceText = arg + '=' + arg_val;
                if (url.match(pattern)) {
                    var tmp = '/(' + arg + '=)([^&]*)/gi';
                    tmp = url.replace(eval(tmp), replaceText);
                    return tmp;
                } else {
                    if (url.match('[\?]')) {
                        return url + '&' + replaceText;
                    } else {
                        return url + '?' + replaceText;
                    }
                }
                return url + '\n' + arg + '\n' + arg_val;
            },
            delUrlParam(url, ref) {
                // 如果不包括此参数
                if (url.indexOf(ref) == -1)
                    return url;
                var arr_url = url.split('?');
                var base = arr_url[0];
                var arr_param = arr_url[1].split('&');
                var index = -1;
                for (var i = 0; i < arr_param.length; i++) {
                    var paired = arr_param[i].split('=');
                    if (paired[0] == ref) {
                        index = i;
                        break;
                    }
                }
                if (index == -1) {
                    return url;
                } else {
                    if (arr_param.length == 1) {
                        return base;
                    }
                    arr_param.splice(index, 1);
                    return base + "?" + arr_param.join('&');
                }
            },
            // 获取对应的参数值
            getUrlParam(url, ref){
                // 如果不包括此参数
                if (url.indexOf(ref) == -1)
                    return -1;
                var arr_url = url.split('?');
                var arr_param = arr_url[1].split('&');
                var index = -1;
                for (var i = 0; i < arr_param.length; i++) {
                    var paired = arr_param[i].split('=');
                    if (paired[0] == ref) {
                        return paired[1]
                    }
                }
                return -1;

            },
            // 处理分页数据格式
            handleSwList() {
                let softwareGoodsList = this.softwareGoodsList;
                var software = [];
                var row = [];
                // 将分页数据处理成[{row:[{},{},{}]},{row:[{},{}]}]的格式
                var pageSize = softwareGoodsList.records.length;
                softwareGoodsList.records.map((item, index) => {
                    row.push(item);
                    if (index == pageSize - 1) {
                        software.push({
                            row: row
                        })
                        row = [];
                    } else {
                        if ((index + 1) % 4 == 0) {
                            software.push({
                                row: row
                            })
                            row = [];
                        }
                    }
                })
                this.software = software;

            },
            // 每页显示的条数
            handleSizeChange(val) {
              document.documentElement.scrollTop = 0;
                // 改变每页显示的条数
                // this.PageSize = val
                // 注意：在改变每页显示的条数时，要将页码显示到第一页
                // this.currentPage = 1
            },
            // 显示第几页
            handleCurrentChange(val) {
                // 改变默认的页数
                this.currentPage = val;
                document.documentElement.scrollTop = 0;
                this.getSoftwareList()
            },
            openProductDetail(productId){
              this.$router.push({path: `/ProductDescription`,query:{productId: productId}});
          }
        },
        // 监听url地址栏
        watch:{

            //监听输入的值越多，输入框越长
            seoKeyword(val) {
                // 实时改变input输入框宽度，防止输入内容超出input默认宽度显示不全
                var len = this.$refs.inputTag.value.length;
                if(this.inputWidth > 603) {
                    if(len > 15) {
                        this.inputWidth = this.$refs.inputTag.value.length * 12 + 603;
                    } else {
                        return;
                    }
                } else {
                    if(len > 15) {
                        this.inputWidth = this.$refs.inputTag.value.length * 12 + 603;
                    }
                }

            },
        }

    }
</script>

<style scoped>
    .cursor-item {
        cursor: pointer;
    }
    .banner {
        background: url("../../img/softwaremall/sw_banner_bg.png") no-repeat top center;
        width: 100%;
        height: 301px;
        margin-bottom: 25px;
    }

    .banner-text {
        font-size: 30px;
        /*font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;*/
        font-weight: bold;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        margin-top: 75px;
        margin-bottom: 26px;
    }

    .father_box {
        width: 603px;
        height: 50px;
        box-sizing: border-box;
        background-color: white;
        border-radius: 37px;
        font-size: 12px;
        text-align: left;
        padding-left: 5px;
        word-wrap: break-word;
        overflow: hidden;
        margin-bottom: 25px;
    }
    .keywordTag{
        font-size:14px;
        margin-left: 6px;
    }
    .inputTag{
        font-size: 16px;
        border: none;
        box-shadow: none;
        outline: none;
        background-color: transparent;
        padding: 0;
        width: auto;
        min-width: 150px;
        vertical-align: top;
        height: 32px;
        color: #495060;
        line-height: 32px;
    }
    .search-button {
        float: right;
        height: 50px;
        width: 90px;
        background-color: #F76F66;
        border: none;
        border-radius: 0px;
        padding: 6px 20px;
    }

    /deep/ .el-input__inner {
        /* border-radius: 37px 0px 0px 37px; */
        /* width: 371px; */
        border: none;
    }

    /* /deep/ .el-input-group__append {
        background-color: #F76F66;
        border: 0;
        border-radius: 0px 37px 37px 0px;
    } */

    /deep/ .el-input {
        width: 200px;
        /* margin-bottom: 25px; */
    }

    .search-key {
        font-size: 15px;
        /*font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;*/
        font-weight: bold;
        color: #FFFFFF;
        line-height: 20px;
    }
    .search-key span:hover{
        color: #F76F66;
    }
    /deep/ .el-input__inner{
      width: 507px;
      height: 50px;
    }

    /deep/ #tabs .el-tabs__item {
        font-size: 18px;
        color: #000000;
        line-height: 24px;
        padding-right: 54px;
    }

    /deep/ #tabs .el-tabs__item.is-active {
        font-weight: bold;
        color: #000000;
    }

    /deep/ #tabs .el-tabs__active-bar {
        width: 42px !important;
        height: 3px;
        background: #000000;
        border-radius: 1px;
    }

    /deep/ #tabs .el-tabs__header {
        margin-bottom: 24px;
    }

    .tabs-text {
        font-size: 14px;
        /* font-weight: bold; */
        color: #414141;
        line-height: 19px;
        padding-right: 39px;
        padding-bottom: 10px;
    }

    .tabs-text:hover{
        color: #F76F66 !important;
    }

    .tabs-active{
        font-weight: bold;
    }

    .tabs-card {
        margin: auto;

    }

    .tabs-card .el-card {
        width: 1200px;
      height: 116px;
      transition: height 0.3s linear;
    }
    /*.tabs-card .el-card:hover {*/
    /*  height: 200px;*/

    /*}*/

    .software-list {
        margin-bottom: 20px;
        float: unset;
        margin-left: auto;
        margin-right: auto;
        width: 1200px;
    }

    .software-row {
        display: flex;
        text-align: center;
    }

    .software-col {
        width: 288px;
        height: 360px;
        margin-top: 16px;

    }

    .software-col:nth-child(-n+3) {
        margin-right: 16px;
    }

    .software-img {
        margin-top: 59px;
        height: 90px;
        width: 90px;
        margin-bottom: 50px;
    }

    .software-name {
        font-size: 16px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #000000;
        line-height: 21px;
        margin-bottom: 20px;
    }

    .software-decribe {
        font-size: 12px;
        color: #414141;
        line-height: 16px;
        margin-bottom: 29px;
    }

    .software-price {
        font-size: 24px;
        color: #D60010;
        line-height: 31px;
    }

    .sw-price-unit {
        font-size: 16px;
    }

    /deep/ #sw-page .el-pager li:hover,
    /deep/ #sw-page .el-pager li.active {
        color: #F56E65;
        border: 1px solid #F56E65;
    }

    /deep/ #sw-page .el-pager li {
        font-size: 16px;
        background-color: #F7F7F7;
        color: #414141;
        width: 25px;
        min-width: 25px;
        height: 25px;
        border-radius: 100%;
        line-height: 25px;
        margin-right: 19px;
        padding: 0px;
    }

</style>
